@import "~@angular/material/prebuilt-themes/indigo-pink.css";

html {
  box-sizing:border-box;
  font-size:16px;
  font-family:Futura PT, sans-serif;
  font-weight:400;
  font-style:normal;
}

*, *:before, *:after {
  box-sizing:inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin:0;
  padding:0;
  font-weight:normal;
}

h1, h2, h3, h4, h5, h6 {
  font-family:oswald, sans-serif;
  font-weight:400;
  font-style:normal;
}

h1, h2, h3, h4, h5, h6, p {
  padding:0.333em 0;
}

ol, ul {
  list-style:none;
}

img {
  max-width:100%;
  height:auto;
  max-height:50em;
}

/* Some breakpoints at font size 16px:

80em = 1200px
50em = 800px
30em = 480px

 */

.container {
  width:100%;
}

header .mat-form-field-flex {
  background:rgba(255,255,255,0.85);
  padding:0 .666em 0.666em;
}
header .mat-form-field-suffix {
  margin-top:0.333em;
  position:relative;
  top:0.333em;

}
header .mat-form-field-flex mat-icon {
  color:#333
}


markdown ul li {
  list-style:disc;
  margin-left:1rem;
  line-height:1.3;
}

@media only screen and (min-width:50em) {
  markdown table tr > * {
    padding:0.25em 2em 0.25em;
  }
}

@media only screen and (min-width:80em) {
  .container {
    width:80em;
    margin:0 auto;
  }
}


/* crazy Markdown table rules */
markdown table {
  -webkit-overflow-scrolling: touch;
  display: inline-flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  min-width: 100%;
  border: 0;
  white-space: nowrap;
  border-spacing: 0;
  table-layout: fixed;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  margin:1rem 0;
}
markdown table thead, markdown tbody, markdown tr {
  min-width:100%;
}
markdown table td {
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
}
markdown tr:hover {
  background:rgba(0, 0, 0, 0.04);
}
markdown th, markdown td {
  padding:0 16px 0 16px;
}
[dir="rtl"] markdown table td,
markdown table td[dir="rtl"] {
  text-align: right;
}
markdown table thead th {
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  text-align: left;
}
[dir="rtl"] markdown table thead th,
markdown table thead th[dir="rtl"] {
  text-align: right;
}
markdown table td,
markdown table thead th {
  padding: 0 16px 0 16px;
}

markdown table {
  display: table;
}
markdown table thead tr {
  min-height: 56px;
}
markdown table tr {
  min-height: 48px;
}
markdown table tr, markdown table thead tr {
  display: flex;
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  align-items: center;
  box-sizing: border-box;
}
markdown td:first-of-type,
markdown th:first-of-type {
  padding-left: 24px;
}
[dir="rtl"] markdown td:first-of-type:not(:only-of-type),
[dir="rtl"] markdown th:first-of-type:not(:only-of-type) {
  padding-left: 0;
  padding-right: 24px;
}
markdown td:last-of-type,
markdown th:last-of-type {
  padding-right: 24px;
}
[dir="rtl"] markdown td:last-of-type:not(:only-of-type),
[dir="rtl"] markdown th:last-of-type:not(:only-of-type),
[dir="rtl"] mat-footer-cell:last-of-type:not(:only-of-type) {
  padding-right: 0;
  padding-left: 24px;
}
markdown td,
markdown th {
  flex: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  word-wrap: break-word;
  min-height: inherit;
}
markdown table {
  --mat-table-row-item-outline-width: 1px;
  white-space: normal;
  background-color: var(--mat-table-background-color);
}
markdown table thead tr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: var(--mat-table-header-container-height, 56px);
  color: var(--mat-table-header-headline-color, rgba(0, 0, 0, 0.87));
  font-family: var(--mat-table-header-headline-font, Roboto, sans-serif);
  line-height: var(--mat-table-header-headline-line-height);
  font-size: var(--mat-table-header-headline-size, 14px);
  font-weight: var(--mat-table-header-headline-weight, 500);
}
markdown table tr {
  height: var(--mat-table-row-item-container-height, 52px);
  color: var(--mat-table-row-item-label-text-color, rgba(0, 0, 0, 0.87));
  border:0;
}
markdown table tr td {
  border:0;
  border-bottom:1px solid rgba(0,0,0,0.12);
}
markdown table tr:last-child td {
  border:0;
}
markdown table tr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-table-row-item-label-text-font, Roboto, sans-serif);
  line-height: var(--mat-table-row-item-label-text-line-height);
  font-size: var(--mat-table-row-item-label-text-size, 14px);
  font-weight: var(--mat-table-row-item-label-text-weight);
}

markdown table th {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
  letter-spacing: var(--mat-table-header-headline-tracking);
  font-weight: inherit;
  line-height: inherit;
}
markdown table td {
  border-color: rgba(0, 0, 0, 0.12);
  border-bottom-style: solid;
  letter-spacing: var(--mat-table-row-item-label-text-tracking);
  line-height: inherit;
}
markdown table tr:last-child {
  border-bottom: none;
}
mat-row.mat-mdc-row,
mat-header-row.mat-mdc-header-row,
mat-footer-row.mat-mdc-footer-row {
  border-bottom: none;
}
markdown table tbody,
markdown table tfoot,
markdown table thead,
.mat-mdc-cell,
.mat-mdc-footer-cell,
.mat-mdc-header-row,
.mat-mdc-row,
.mat-mdc-footer-row,
markdown table .mat-mdc-header-cell {
  background: inherit;
}
markdown table mat-header-row.mat-mdc-header-row,
markdown table mat-row.mat-mdc-row,
markdown table mat-footer-row.mat-mdc-footer-cell {
  height: unset;
}
markdown th,
markdown td {
  align-self: stretch;
}



